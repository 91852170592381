export const getPasswordIcon = (
  password: string,
  isPasswordVisible: boolean,
) => {
  if (password && isPasswordVisible) {
    return "eye-opened";
  }
  if (password && !isPasswordVisible) {
    return "eye-closed";
  }
  return undefined;
};

export const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const toCapitalizedWords = (name: string) => {
  // Match words that start with an alphabet letter followed by zero or more lowercase letters
  const words =
    (typeof name === "string" && name?.match(/[A-Za-z][a-z]*/g)) || [];

  return words.map((word) => capitalize(word)).join(" ");
};

export const getParenthesesWithPositiveIntegerRegexp = (): RegExp => {
  return /\([1-9]\d*\)$/;
};

export const getDuplicateFolderName = (
  folderName: string,
  duplicateIndex: number,
): string => {
  return `${folderName}(${duplicateIndex})`;
};

export const getHighestExistingDuplicateIndex = (
  baseFolderName: string,
  peerFolderNames: string[],
  parenthesesWithPositiveIntegerRegexp: RegExp,
): number => {
  const similarPeers = peerFolderNames
    .filter((item) => parenthesesWithPositiveIntegerRegexp.test(item))
    .filter((item) => item.slice(0, item.lastIndexOf("(")) === baseFolderName);

  if (similarPeers.length === 0) throw new Error("No similar peers");

  return similarPeers
    .map((item) =>
      Number.parseInt(item.slice(item.lastIndexOf("(") + 1, -1), 10),
    )
    .sort((a, b) => b - a)[0] as number;
};

export const processPeerDuplicate = (
  folderName: string,
  peerFolderNames: string[],
): string => {
  if (!peerFolderNames.includes(folderName)) return folderName;
  const parenthesesWithPositiveIntegerRegexp: RegExp =
    getParenthesesWithPositiveIntegerRegexp();
  const folderNameCompliesWithRule =
    parenthesesWithPositiveIntegerRegexp.test(folderName);
  if (folderNameCompliesWithRule) {
    const baseFolderName = folderName.slice(0, folderName.lastIndexOf("("));
    const highestExistingIndex: number = getHighestExistingDuplicateIndex(
      baseFolderName,
      peerFolderNames,
      parenthesesWithPositiveIntegerRegexp,
    );
    return getDuplicateFolderName(baseFolderName, highestExistingIndex + 1);
  }
  const nameWithAdditionalParentheses = getDuplicateFolderName(folderName, 1);
  if (!peerFolderNames.includes(nameWithAdditionalParentheses))
    return nameWithAdditionalParentheses;
  const baseFolderName = folderName;
  const highestExistingIndex: number = getHighestExistingDuplicateIndex(
    baseFolderName,
    peerFolderNames,
    parenthesesWithPositiveIntegerRegexp,
  );
  return getDuplicateFolderName(baseFolderName, highestExistingIndex + 1);
};
