import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, subscription } from "store";
import {
  setRetryFailed,
  setPaymentFailedModal,
  setPremiumActivatedModal,
} from "store/subscription";
import { getPaymentMethod } from "store/subscription/thunks";

import {
  PaymentMethodButton,
  BreadcrumbsPaymentMethod,
  ConfirmModal,
} from "components";
import { Button, Modal } from "components/UI";

import { PATHES } from "constants/pathes";
import { useEffectOnce, useSubscription } from "hooks";
import { Subscriptions } from "api";

import styles from "./styles.module.scss";

export const SubscriptionPaymentMethod = () => {
  const dispatch = useAppDispatch();
  const { isRetryFailed, paymentMethod } = useAppSelector(subscription);
  const location = useLocation();
  const { isRetry } = location?.state || {};
  const { t } = useTranslation("Subscription", {
    keyPrefix: "PaymentMethod",
  });
  const { t: tA } = useTranslation("Pages", {
    keyPrefix: "Account",
  });
  const { t: tF } = useTranslation("Subscription", {
    keyPrefix: "PaymentFailed",
  });
  const { isFailed } = useSubscription();

  useEffectOnce(() => {
    if (!paymentMethod) {
      dispatch(getPaymentMethod());
    }
  });

  const navigate = useNavigate();
  const handleGoToPayment = () => {
    navigate(PATHES.SUBSCRIPTION_UPDATE_PAYMENT_METHOD);
  };

  const handlePay = async () => {
    const res = await Subscriptions.retryStripeSubscription();

    if (res?.message) {
      navigate(PATHES.SUBSCRIPTION);
      dispatch(setPremiumActivatedModal(true));
    } else {
      dispatch(setPaymentFailedModal(false));
      dispatch(setRetryFailed(true));
    }
  };

  return (
    <div className={styles.SubscriptionPaymentMethod}>
      <Modal isShowed={isRetryFailed}>
        <ConfirmModal
          title={tF("retryTitle")}
          info={tF("retryInfo")}
          submitTitle={tF("retrySubmitButtonTitle")}
          submitAction={() => navigate(PATHES.SUBSCRIPTION_PLANS)}
          cancelTitle={tF("cancelButtonTitle")}
          cancelAction={() => dispatch(setRetryFailed(false))}
        />
      </Modal>

      <h1 className={styles.title}>{tA("title")}</h1>
      <BreadcrumbsPaymentMethod />
      <div className={styles.card}>
        <div>**** **** **** {paymentMethod?.last4}</div>
        <div className={styles.cardExp}>
          <div className={styles.label}>Exp:</div>
          <div>
            {Number(paymentMethod?.expirationMonth) < 10 && "0"}
            {paymentMethod?.expirationMonth}/
            {`${paymentMethod?.expirationYear}`.slice(-2)}
          </div>
        </div>
      </div>
      {(isRetry || isFailed) && (
        <Button
          variant="primary"
          title={t("retryButtonTitle")}
          onClick={handlePay}
        />
      )}
      <PaymentMethodButton
        title={t("updateButtonTitle")}
        onClick={handleGoToPayment}
      />
    </div>
  );
};
