import axios from "axios";
import fileDownload from "js-file-download";

import { useAppSelector, settings } from "store";
import { PDF, SharedDocuments } from "api";

import { toArrayBuffer } from "utils";
import { useUploadRequest } from "hooks";

export const useDownload = () => {
  const { handleAddWaterMark } = useUploadRequest();
  const { user } = useAppSelector(settings);

  const downloadPdfDoc = async (id: string) => {
    try {
      let res = await PDF.getPdfDocument(id);
      if (!res?.document) {
        res = await PDF.getSignedPdfDocument(id);
      }
      if (res?.document) {
        const { url, name } = res.document || {};
        axios
          .get(url, {
            responseType: "blob",
          })
          .then(async (res) => {
            if (user?.isPremium || user?.isWebPremium) {
              fileDownload(res.data, `${name}.pdf`);
            } else {
              const newFileData = await toArrayBuffer(res.data);
              const updatedBuffer =
                newFileData &&
                (await handleAddWaterMark(newFileData as ArrayBuffer));
              const blob =
                updatedBuffer &&
                new Blob([updatedBuffer], {
                  type: "application/pdf",
                });
              blob && fileDownload(blob, `${name}.pdf`);
            }
          });
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  const downloadInboxDoc = async (id: string) => {
    try {
      const res = await SharedDocuments.downloadInboxDoc({ id });
      if (res?.file) {
        const { url } = res.file || {};
        axios
          .get(url, {
            responseType: "blob",
          })
          .then(async (result) => {
            if (user?.isPremium || user?.isWebPremium) {
              fileDownload(result.data, `${res.name}.pdf`);
            } else {
              const newFileData = await toArrayBuffer(result.data);
              const updatedBuffer =
                newFileData &&
                (await handleAddWaterMark(newFileData as ArrayBuffer));
              const blob =
                updatedBuffer &&
                new Blob([updatedBuffer], {
                  type: "application/pdf",
                });
              blob && fileDownload(blob, `${res.name}.pdf`);
            }
          });
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  const downloadSentDoc = async (id: string) => {
    try {
      const res = await SharedDocuments.downloadSentDoc({ id });
      if (res?.file) {
        const { url } = res.file || {};
        axios
          .get(url, {
            responseType: "blob",
          })
          .then(async (result) => {
            if (user?.isPremium || user?.isWebPremium) {
              fileDownload(result.data, `${res.name}.pdf`);
            } else {
              const newFileData = await toArrayBuffer(result.data);
              const updatedBuffer =
                newFileData &&
                (await handleAddWaterMark(newFileData as ArrayBuffer));
              const blob =
                updatedBuffer &&
                new Blob([updatedBuffer], {
                  type: "application/pdf",
                });
              blob && fileDownload(blob, `${res.name}.pdf`);
            }
          });
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  const downloadDraftDoc = async (id: string) => {
    try {
      const res = await SharedDocuments.downloadDraftDoc({ id });
      if (res?.file) {
        const { url } = res.file || {};
        axios
          .get(url, {
            responseType: "blob",
          })
          .then(async (result) => {
            if (user?.isPremium || user?.isWebPremium) {
              fileDownload(result.data, `${res.name}.pdf`);
            } else {
              const newFileData = await toArrayBuffer(result.data);
              const updatedBuffer =
                newFileData &&
                (await handleAddWaterMark(newFileData as ArrayBuffer));
              const blob =
                updatedBuffer &&
                new Blob([updatedBuffer], {
                  type: "application/pdf",
                });
              blob && fileDownload(blob, `${res.name}.pdf`);
            }
          });
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  return {
    downloadPdfDoc,
    downloadInboxDoc,
    downloadSentDoc,
    downloadDraftDoc,
  };
};
