import { FC } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { useAppSelector, useAppDispatch, auth } from "store";
import { setLanguage, setDateFormat, setTimeFormat } from "store/dashboard";

import { PdfViewerRecipientSigning, GuestDocReady } from "components";
import { PATHES } from "constants/pathes";
import { ATTACHMENTS_KEY } from "constants/index";
import { useEffectOnce } from "hooks";
import i18n from "i18n";

import { Private } from "./Private";
import { Public } from "./Public";

const Router: FC = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector(auth);

  useEffectOnce(() => {
    const browserLng = navigator.language?.split("-")[0];
    const storageLng = localStorage.getItem("lng");
    const storageDateFormat = localStorage.getItem("dateFormat");
    const storageTimeFormat = localStorage.getItem("timeFormat");

    if (window.location.href.includes("shared-documents/signing")) {
      localStorage.removeItem(ATTACHMENTS_KEY);
      localStorage.removeItem("instantJSON");
      sessionStorage.removeItem("IsSigningStarted");
    }

    if (navigator.language === "en-US") {
      !storageDateFormat && dispatch(setDateFormat("MMM-DD-YYYY"));
      !storageTimeFormat && dispatch(setTimeFormat("hh:mm a"));
    }

    if (storageLng) {
      i18n.changeLanguage(storageLng);
      return;
    }

    if (!storageLng && browserLng) {
      dispatch(setLanguage(browserLng));
      i18n.changeLanguage(browserLng);
    }
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={`${PATHES.GUEST_RECIPIENT_DOCUMENT}/:linkId?`}
          element={<PdfViewerRecipientSigning />}
        />
        <Route
          path={`${PATHES.GUEST_RECIPIENT_DOCUMENT_READY}/:linkId?`}
          element={<GuestDocReady />}
        />
        <Route
          path={`${PATHES.GUEST_OWNER_DOCUMENT}/:ownerLinkId?`}
          element={<PdfViewerRecipientSigning />}
        />
        <Route
          path="*"
          element={<>{isAuthenticated ? <Private /> : <Public />}</>}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
