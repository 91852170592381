import { FC } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, signings } from "store";
import { setIsDeleteDraftItemsModal } from "store/signings";
import {
  deleteDrafts,
  undoDeleteDraft,
  getDrafts,
} from "store/signings/thunks";

import { AreYouSureModal } from "components";
import { Modal } from "components/UI";

import { toastUndo } from "utils";

export const SigningsTableModals: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Table", {
    keyPrefix: "SigningsDraft.Modals",
  });
  const { isDeleteDraftItemsModal, draftsToDelete } = useAppSelector(signings);

  const handleCloseAreYouSureDeleteDraft = () => {
    dispatch(setIsDeleteDraftItemsModal(false));
  };

  const handleConfirmAreYouSureDeleteDraft = async () => {
    const id = draftsToDelete[draftsToDelete.length - 1];
    let text = t("deleteToastText");
    let textUndo = t("deleteToastTextUndo");

    if (draftsToDelete.length > 1) {
      text = t("deletePluralToastText");
      textUndo = t("deletePluralToastTextUndo");
    }

    const res = await dispatch(deleteDrafts({ documentIds: draftsToDelete }));

    if (res?.payload) {
      toastUndo({
        id,
        text,
        textUndo,
        onUndo: async () => {
          await dispatch(undoDeleteDraft({ documentIds: draftsToDelete }));
          dispatch(getDrafts());
        },
      });
    }

    handleCloseAreYouSureDeleteDraft();
  };

  return (
    <>
      <Modal
        isShowed={isDeleteDraftItemsModal}
        onClose={handleCloseAreYouSureDeleteDraft}
      >
        <AreYouSureModal
          onClose={handleCloseAreYouSureDeleteDraft}
          onSubmit={handleConfirmAreYouSureDeleteDraft}
          info={t("areYouSureDeleteInfo")}
          confirmButtonTitle={t("areYouSureDeleteIConfirmButtonTitle")}
        />
      </Modal>
    </>
  );
};
