import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { IAuthResponse } from "types";
import { toastError, toastErrorTwoRows, stripeErrors } from "utils";
import { BASE_URL } from "../constants";
import { setSession, getSession, removeSession } from "./storage";

export const $http = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || BASE_URL,
  timeout: 50000,
  timeoutErrorMessage: "Timeout Error",
  withCredentials: false,
});

const connectionErrorStatuses: Record<string, string> = {
  ERR_NETWORK: "ERR_NETWORK",
  ECONNABORTED: "ECONNABORTED",
  ERR_NAME_NOT_RESOLVED: "ERR_NAME_NOT_RESOLVED",
};

const handleHttpError = (error: any) => {
  if (axios.isAxiosError(error)) {
    const response = error?.response;

    // Connection errors
    if (connectionErrorStatuses[error.code as string]) {
      toastError("No internet connection", true);
      return Promise.reject(new Error(error.message));
    }
    //

    // Server errors
    if (response && response.status >= 500) {
      if (response?.data?.declineCode) {
        toastErrorTwoRows(
          stripeErrors("payment_failed"),
          stripeErrors(response?.data?.declineCode || ""),
        );
        return Promise.reject(new Error(error.message));
      }

      toastError(
        "Oops, looks like our servers returned an unexpected error. \nPlease try again in a while and contact support if the problem persists.",
      );
      return Promise.reject(new Error(error.message));
    }
    //

    // Too many requests
    if (response && response.status === 429) {
      toastError(
        "We apologize for any inconvenience caused. \nKindly reduce the frequency of your requests and try again later. Thank you for your patience and understanding.",
      );
      return Promise.reject(new Error(error.message));
    }

    if (axios.isCancel(error)) {
      console.log("request cancelled");
      return Promise.reject(new Error(error.message));
    }

    return Promise.reject(error);
  }

  return Promise.reject(error);
};

$http.interceptors.request.use(
  (request) => {
    const token = getSession()?.accessToken;

    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

$http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const config = error?.config;
    const status = error?.response?.status;

    if (status === 401) {
      return Promise.reject(error);
    }

    if (config?.raw) {
      return Promise.reject(error);
    }

    return handleHttpError(error);
  },
);

const refreshAccessToken = async () => {
  const { accessToken, refreshToken } = getSession();

  try {
    if (!accessToken || !refreshToken) {
      throw new Error("Session expired");
    }

    const res = await axios.post<IAuthResponse>(
      `${process.env.REACT_APP_BASE_URL || BASE_URL}/auth/refresh`,
      {},
      {
        headers: { Authorization: `Bearer ${refreshToken}` },
      },
    );

    setSession({
      accessToken: res.data.accessToken,
      refreshToken: res.data.refreshToken,
      user: res.data.user,
    });
    return Promise.resolve();
  } catch (e) {
    removeSession();
    setTimeout(() => {
      document.location.href = "/";
    }, 100);
    // toastError("Session expired");
    return Promise.reject(new Error("Unauthorized"));
  }
};

createAuthRefreshInterceptor($http, refreshAccessToken, {
  skipWhileRefreshing: false,
  shouldRefresh(error): boolean {
    const { accessToken } = getSession();
    return !!accessToken && error?.response?.status === 401;
  },
});
