import { isAxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  useAppDispatch,
  useAppSelector,
  folders,
  trashBin,
  contacts,
  signings,
  settings,
  dashboard,
} from "store";
import {
  setRecepientsModal,
  setItemsToMove,
  setIsMovingItemsModal,
  setRenameModalItem,
  setCertifyModalItem,
  setIsFolderMoving,
  setSelectedItems,
  setIsDeleteItemsModal,
  setCreateModalItem,
  setAllGeneralItems,
  setCurrentMovingFolder,
  // setSoftDeletedItems,
} from "store/folders";
import {
  setIsDeleteItemsModal as setIsHardDeleteItemsModal,
  setSelectedItems as setSelectedTrashItems,
  setAllDeletedItems,
  setItemsToDelete,
  setItemsToRestore,
  setIsRestoreItemsModal,
  setSoftDeletedItems as setSoftDeletedTrashItems,
} from "store/trashBin";
import {
  setEditableContact,
  setIsContactModal,
  setIsDeleteItemsModal as setIsDeleteContactsModal,
  setItemsToDelete as setContactsToDelete,
} from "store/contacts";
import {
  setIsDeleteSentItemsModal,
  setIsDeleteDraftItemsModal,
  setIsMovingItemsModal as setSentIsMovingItemsModal,
  setIsSentCancelRequestModal,
  setDraftsToDelete,
  setSentToDelete,
  setSentToCancelRequest,
  setIsInboxRejectRequestModal,
  setInboxToRejectRequest,
  setInboxToDelete,
  setIsDeleteInboxItemsModal,
  setInboxDoc,
  setIsInboxItemsMoved,
  setIsSentItemsMoved,
} from "store/signings";
import {
  setErrorModal,
  setSearch,
  setIsViewHistoryModal,
} from "store/dashboard";
import { setSubscriptionRequiredModal } from "store/subscription";
import { getFolder } from "store/folders/thunks";
import { getFolder as getTrashFolder } from "store/trashBin/thunks";
import { getQuota } from "store/dashboard/thunks";
import {
  deleteInbox,
  undoDeleteInbox,
  deleteSent,
  undoDeleteSent,
} from "store/signings/thunks";
import { Folders, TrashBin, SharedDocuments } from "api";

import { toastError, toastSuccess, toastUndo } from "utils";
import { useDownload } from "hooks";

import { SigningsType, TSharedDocumentStatus } from "types";

import { IconName } from "components/UI/Icon";
import { PATHES } from "constants/pathes";

interface IAction {
  name: IconName;
  isTrash?: boolean;
  isContact?: boolean;
  signingsType?: SigningsType;
  signingsStatus?: TSharedDocumentStatus;
  isGlobalSearch?: boolean;
  isViewHistory?: boolean;
}

export const useActions = (id?: string) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("Hooks", {
    keyPrefix: "useActions",
  });
  const { t: tS } = useTranslation("Table", {
    keyPrefix: "SigningsSent.Modals",
  });
  const { user } = useAppSelector(settings);
  const {
    downloadPdfDoc,
    downloadInboxDoc,
    downloadSentDoc,
    downloadDraftDoc,
  } = useDownload();
  const {
    selectedItems,
    itemsToMove,
    currentMovingFolder,
    currentFolder,
    currentContextFolder,
    allGeneralItems,
  } = useAppSelector(folders);
  const {
    selectedItems: selectedTrashItems,
    currentFolder: currentTrashFolder,
    allDeletedItems,
    itemsToDelete,
    itemsToRestore,
  } = useAppSelector(trashBin);
  const { selectedItems: selectedContactsItems } = useAppSelector(contacts);
  const {
    selectedDraftItems,
    selectedSentItems,
    sentToMove,
    selectedInboxItems,
    inboxToMove,
    inboxToDelete,
    sentToDelete,
  } = useAppSelector(signings);
  const { selectedSearchItems } = useAppSelector(dashboard);

  const handleOpenCreateModal = () => {
    dispatch(setCreateModalItem(true));
  };

  const handleDownload = (isGlobalSearch?: boolean) => {
    const itemsToDownload = isGlobalSearch
      ? selectedSearchItems
      : selectedItems;
    id
      ? downloadPdfDoc(id)
      : itemsToDownload.forEach((item) => item.id && downloadPdfDoc(item.id));
  };

  const handleDeleteSuccess = async (successMessage?: string) => {
    dispatch(setErrorModal(null));

    const refreshState = () => {
      dispatch(getFolder({ id: currentFolder }));
      dispatch(setSelectedItems([]));
      dispatch(setItemsToMove([]));
      // dispatch(setSoftDeletedItems([]));
    };
    try {
      const res = await Folders.deleteFolder({
        folderIds: itemsToMove,
      });
      if (res) {
        successMessage && toastSuccess(successMessage);
        dispatch(
          setAllGeneralItems(
            allGeneralItems.filter(
              (item) => !itemsToMove.includes(item.folder.id),
            ),
          ),
        );
        refreshState();
        return true;
      }
    } catch (error) {
      console.log("error:", error);
      if (isAxiosError(error)) {
        error?.message &&
          toastError(
            Array.isArray(error.message) ? error.message[0] : error.message,
          );
      }

      const openedFolderData = allGeneralItems.find(
        (item) => item.folder?.id === currentFolder,
      )?.items;

      const isFolder =
        openedFolderData?.find((item) => item.id === id)?.type === "FOLDER";
      const isPlural = itemsToMove.length === 0 && selectedItems.length > 1;
      const itemList = openedFolderData?.filter(
        (el) =>
          el.id === id ||
          itemsToMove.includes(el.id) ||
          selectedItems.some((elem) => elem.id === el.id),
      );
      const fileNames = itemList?.map((el) => el.name).join(", ");

      let title = t("deleteFileSuccessToastTitle");
      const text = t("deleteFileSuccessToastText", { fileNames });

      if (isPlural) {
        title = t("deletePluralSuccessToastTitle");
      } else if (isFolder) {
        title = t("deleteFolderSuccessToastTitle");
      }

      dispatch(
        setErrorModal({
          title,
          text,
          onSubmit: handleDeleteSuccess,
          onCancel: () => {
            refreshState();
            // dispatch(setSoftDeletedItems([]));
          },
        }),
      );
    } finally {
      dispatch(setIsDeleteItemsModal(false));
    }
  };

  const handleDelete = (isGlobalSearch?: boolean) => {
    const selectedItemsToDelete = isGlobalSearch
      ? selectedSearchItems
      : selectedItems;
    const items =
      itemsToMove.length > 0
        ? [...itemsToMove]
        : selectedItemsToDelete.map((el) => el.id);

    dispatch(setItemsToMove(id ? [id] : items));
    // if (id) {
    //   dispatch(setSoftDeletedItems([...softDeletedItems, id]));
    // } else if (itemsToMove.length > 0) {
    //   dispatch(setSoftDeletedItems([...itemsToMove]));
    // } else if (selectedItems.length > 0) {
    //   dispatch(setSoftDeletedItems([...selectedItems.map((el) => el.id)]));
    // }
    dispatch(setIsDeleteItemsModal(true));
  };

  const handleHardDeleteSuccess = async (successMessage?: string) => {
    dispatch(setErrorModal(null));

    const refreshState = () => {
      dispatch(getTrashFolder({ id: currentTrashFolder }));
      dispatch(setSelectedTrashItems([]));
      dispatch(getQuota());
    };

    const items =
      selectedTrashItems.length > 0
        ? [...selectedTrashItems.map((el) => el.id)]
        : itemsToDelete;

    try {
      const res = await TrashBin.deleteFolder({
        folderIds: items,
      });
      if (res) {
        successMessage && toastSuccess(successMessage);
        dispatch(
          setAllDeletedItems(
            allDeletedItems.filter(
              (item) => !itemsToDelete.includes(item.folder.id),
            ),
          ),
        );
        refreshState();
        return true;
      }
    } catch (error) {
      console.log("error:", error);
      if (isAxiosError(error)) {
        error?.message &&
          toastError(
            Array.isArray(error.message) ? error.message[0] : error.message,
          );
      }

      const openedFolderData = allDeletedItems.find(
        (item) => item.folder?.id === currentTrashFolder,
      )?.items;

      const isFolder =
        openedFolderData?.find((item) => item.id === id)?.type === "FOLDER";
      const isPlural =
        itemsToDelete.length === 0 && selectedTrashItems.length > 1;
      const itemList = openedFolderData?.filter(
        (el) =>
          el.id === id ||
          itemsToDelete.includes(el.id) ||
          selectedTrashItems.some((elem) => elem.id === el.id),
      );
      const fileNames = itemList?.map((el) => el.name).join(", ");

      let title = t("hardDeleteFileSuccessToastTitle");
      const text = t("hardDeleteFileSuccessToastText", { fileNames });

      if (isPlural) {
        title = t("hardDeletePluralSuccessToastTitle");
      } else if (isFolder) {
        title = t("hardDeleteFolderSuccessToastTitle");
      }

      dispatch(
        setErrorModal({
          title,
          text,
          onSubmit: handleHardDeleteSuccess,
          onCancel: () => {
            refreshState();
            dispatch(setSoftDeletedTrashItems([]));
          },
        }),
      );
    } finally {
      dispatch(setIsHardDeleteItemsModal(false));
    }
  };

  const handleHardDelete = async (isGlobalSearch?: boolean) => {
    const selectedItemsToDelete = isGlobalSearch
      ? selectedSearchItems
      : selectedTrashItems;
    if (id) {
      dispatch(setItemsToDelete([id]));
    } else if (selectedItemsToDelete.length > 0) {
      dispatch(setItemsToDelete([...selectedItemsToDelete.map((el) => el.id)]));
    }

    dispatch(setIsHardDeleteItemsModal(true));
  };

  const handleShare = (isGlobalSearch?: boolean) => {
    const itemsToShare = isGlobalSearch ? selectedSearchItems : selectedItems;
    dispatch(setRecepientsModal(id || itemsToShare.map((el) => el.id)[0]));
  };

  const handleRename = (isGlobalSearch?: boolean) => {
    const itemsToRename = isGlobalSearch ? selectedSearchItems : selectedItems;
    dispatch(
      setRenameModalItem(
        currentContextFolder?.id || id || itemsToRename.map((el) => el.id)[0],
      ),
    );
  };

  const handleCertify = () => {
    if (user?.isPremium || user?.isWebPremium) {
      dispatch(
        setCertifyModalItem(
          currentContextFolder?.id || id || selectedItems.map((el) => el.id)[0],
        ),
      );
    } else {
      dispatch(setSubscriptionRequiredModal(true));
      // handleOpenTryPremium();
    }
  };

  const handleMove = (isGlobalSearch?: boolean) => {
    const itemsToHandleMove = isGlobalSearch
      ? selectedSearchItems
      : selectedItems;
    const items =
      itemsToMove.length > 0
        ? [...itemsToMove]
        : itemsToHandleMove.map((el) => el.id);
    dispatch(setIsMovingItemsModal(true));
    dispatch(setItemsToMove(id ? [id] : items));
    // if (id) {
    //   dispatch(setSoftDeletedItems([...softDeletedItems, id]));
    // } else if (itemsToMove.length > 0) {
    //   dispatch(setSoftDeletedItems([...itemsToMove]));
    // } else if (selectedItems.length > 0) {
    //   dispatch(setSoftDeletedItems([...selectedItems.map((el) => el.id)]));
    // }
  };

  const handleMoveSuccess = async ({
    isSent,
    isInbox,
  }: {
    isSent?: boolean;
    isInbox?: boolean;
  }) => {
    dispatch(setErrorModal(null));

    const refreshState = () => {
      if (!isInbox && !isSent) {
        dispatch(getFolder({ id: currentFolder }));
        dispatch(getFolder({ id: currentMovingFolder }));
        dispatch(setSelectedItems([]));
        dispatch(setItemsToMove([]));
        dispatch(setCurrentMovingFolder(""));
      } else if (isInbox) {
        dispatch(setIsInboxItemsMoved(true));
      } else if (isSent) {
        dispatch(setIsSentItemsMoved(true));
      }
    };
    try {
      let res;
      if (isSent) {
        await Promise.all(
          itemsToMove.map((el) =>
            SharedDocuments.moveSentDoc(el, currentMovingFolder),
          ),
        );
        refreshState();
        return true;
      }
      if (isInbox) {
        await Promise.all(
          itemsToMove.map((el) =>
            SharedDocuments.moveInboxDoc(el, currentMovingFolder),
          ),
        );
        refreshState();
        return true;
      }
      if (!isSent && !isInbox) {
        res = await Folders.moveDocument({
          parentFolderId: currentMovingFolder,
          folderIds: itemsToMove,
        });
      }
      if (res?.folder) {
        // toastSuccess("Files were successfully moved!");
        res.items
          ?.filter((item) => item.type === "FOLDER")
          .forEach((item: any) => {
            dispatch(getFolder({ id: item.id }));
          });
        refreshState();
        return true;
      }
    } catch (error) {
      console.log("error:", error);
      // dispatch(setSoftDeletedItems([]));

      if (isAxiosError(error)) {
        error?.message &&
          toastError(
            Array.isArray(error.message) ? error.message[0] : error.message,
          );
      }

      const openedFolderData = allGeneralItems.find(
        (item) => item.folder?.id === currentFolder,
      )?.items;

      const isFolder =
        openedFolderData?.find((item) => item.id === id)?.type === "FOLDER";
      const isPlural = itemsToMove.length > 1;
      const itemList = openedFolderData?.filter(
        (el) => el.id === id || itemsToMove.includes(el.id),
      );
      const fileNames = itemList?.map((el) => el.name).join(", ");

      let title = t("moveFileSuccessToastTitle");
      const text = t("moveFileSuccessToastText", { fileNames });

      if (isPlural) {
        title = t("movePluralSuccessToastTitle");
      } else if (isFolder) {
        title = t("moveFolderSuccessToastTitle");
      }

      dispatch(
        setErrorModal({
          title,
          text,
          onSubmit: () => handleMoveSuccess({}),
          onCancel: () => {
            refreshState();
            // dispatch(setSoftDeletedItems([]));
          },
        }),
      );
    } finally {
      dispatch(setIsFolderMoving(false));
    }
  };

  const handleRestore = (isGlobalSearch?: boolean) => {
    const selectedItemsToRestore = isGlobalSearch
      ? selectedSearchItems
      : selectedTrashItems;
    if (id) {
      dispatch(setItemsToRestore([id]));
    } else if (selectedItemsToRestore.length > 0) {
      dispatch(
        setItemsToRestore([...selectedItemsToRestore.map((el) => el.id)]),
      );
    }
    dispatch(setIsRestoreItemsModal(true));
  };

  const handleRestoreSuccess = async () => {
    dispatch(setErrorModal(null));

    const refreshState = () => {
      dispatch(getTrashFolder({ id: currentTrashFolder }));
      dispatch(setSelectedTrashItems([]));
      dispatch(setItemsToRestore([]));
    };
    const items =
      selectedTrashItems.length > 0
        ? [...selectedTrashItems.map((el) => el.id)]
        : itemsToRestore;

    try {
      const res = await TrashBin.restoreFolder({
        folderIds: items,
      });
      if (res) {
        // toastSuccess("Items were restored");
        dispatch(
          setAllDeletedItems(
            allDeletedItems.filter(
              (item) => !itemsToRestore.includes(item.folder.id),
            ),
          ),
        );
        refreshState();
        return true;
      }
    } catch (error) {
      console.log("error:", error);
      if (isAxiosError(error)) {
        error?.message &&
          toastError(
            Array.isArray(error.message) ? error.message[0] : error.message,
          );
      }

      const openedFolderData = allDeletedItems.find(
        (item) => item.folder?.id === currentTrashFolder,
      )?.items;

      const isFolder =
        openedFolderData?.find((item) => item.id === id)?.type === "FOLDER";
      const isPlural =
        itemsToRestore.length === 0 && selectedTrashItems.length > 1;
      const itemList = openedFolderData?.filter(
        (el) =>
          el.id === id ||
          itemsToRestore.includes(el.id) ||
          selectedTrashItems.some((elem) => elem.id === el.id),
      );
      const fileNames = itemList?.map((el) => el.name).join(", ");

      let title = t("restoreFileSuccessToastTitle");
      const text = t("restoreFileSuccessToastText", { fileNames });

      if (isPlural) {
        title = t("restorePluralSuccessToastTitle");
      } else if (isFolder) {
        title = t("restoreFolderSuccessToastTitle");
      }

      dispatch(
        setErrorModal({
          title,
          text,
          onSubmit: handleRestoreSuccess,
          onCancel: () => {
            refreshState();
            dispatch(setSoftDeletedTrashItems([]));
          },
        }),
      );
    } finally {
      dispatch(setIsRestoreItemsModal(false));
    }
  };

  const handleEditContact = () => {
    const selectedId = id || selectedContactsItems[0].id;
    if (selectedId) {
      dispatch(setEditableContact(selectedId));
      dispatch(setIsContactModal(true));
    }
  };

  const handleDeleteContact = () => {
    const selectedIds = id ? [id] : selectedContactsItems.map((el) => el.id);
    dispatch(setIsDeleteContactsModal(true));
    dispatch(setContactsToDelete(selectedIds));
  };

  const getDeleteFunction = ({
    isTrash,
    isContact,
    isGlobalSearch,
  }: IAction) => {
    if (isTrash) {
      return () => handleHardDelete(isGlobalSearch);
    }
    if (isContact) {
      return handleDeleteContact;
    }
    return () => handleDelete(isGlobalSearch);
  };

  const handleDeleteSent = (isGlobalSearch?: boolean) => {
    const itemsToDelete = isGlobalSearch
      ? selectedSearchItems
      : selectedSentItems;
    dispatch(setIsDeleteSentItemsModal(true));
    const selectedIds = id ? [id] : itemsToDelete.map((el) => el.id);
    dispatch(setSentToDelete(selectedIds));
  };

  const handleDeleteInbox = (isGlobalSearch?: boolean) => {
    const itemsToDelete = isGlobalSearch
      ? selectedSearchItems
      : selectedInboxItems;
    dispatch(setIsDeleteInboxItemsModal(true));
    const selectedIds = id ? [id] : itemsToDelete.map((el) => el.id);
    dispatch(setInboxToDelete(selectedIds));
  };

  const handleDeleteInboxSuccess = async (refreshState: () => void) => {
    const id = inboxToDelete[inboxToDelete.length - 1];
    let text = tS("movingFileToTrashBinToastText");
    let textUndo = tS("movingFileToTrashBinToastTextUndo");

    if (inboxToDelete.length > 1) {
      text = tS("movingPluralToTrashBinToastText");
      textUndo = tS("movingPluralToTrashBinToastTextUndo");
    }

    const filteredInboxItems = inboxToDelete.filter(
      (el) =>
        !selectedInboxItems
          .filter((item) => item.status === "NEED_TO_SIGN")
          .some((item) => item.id === el),
    );

    await Promise.all(
      filteredInboxItems.map((el) => dispatch(deleteInbox(el))),
    );
    refreshState();

    toastUndo({
      id,
      text,
      textUndo,
      onUndo: async () => {
        await Promise.all(
          filteredInboxItems.map((el) => dispatch(undoDeleteInbox(el))),
        );
        dispatch(setInboxToDelete([]));
        refreshState();
      },
    });
  };

  const handleDeleteSentSuccess = async (refreshState: () => void) => {
    const id = sentToDelete[sentToDelete.length - 1];
    let text = tS("movingFileToTrashBinToastText");
    let textUndo = tS("movingFileToTrashBinToastTextUndo");

    if (sentToDelete.length > 1) {
      text = tS("movingPluralToTrashBinToastText");
      textUndo = tS("movingPluralToTrashBinToastTextUndo");
    }

    const filteredSentItems = sentToDelete.filter(
      (el) =>
        !selectedSentItems
          .filter((item) => item.status === "IN_PROGRESS")
          .some((item) => item.id === el),
    );

    await Promise.all(filteredSentItems.map((el) => dispatch(deleteSent(el))));
    refreshState();

    toastUndo({
      id,
      text,
      textUndo,
      onUndo: async () => {
        await Promise.all(
          filteredSentItems.map((el) => dispatch(undoDeleteSent(el))),
        );
        dispatch(setSentToDelete([]));
        refreshState();
      },
    });
  };

  const handleCancelRequset = (isGlobalSearch?: boolean) => {
    const itemsToCancel = isGlobalSearch
      ? selectedSearchItems
      : selectedSentItems;
    const selectedIds = id ? [id] : itemsToCancel.map((el) => el.id);
    dispatch(setIsSentCancelRequestModal(true));
    dispatch(setSentToCancelRequest(selectedIds));
  };

  const handleRejectRequest = (isGlobalSearch?: boolean) => {
    const itemsToReject = isGlobalSearch
      ? selectedSearchItems
      : selectedInboxItems;
    const selectedIds = id ? [id] : itemsToReject.map((el) => el.id);
    dispatch(setIsInboxRejectRequestModal(true));
    dispatch(setInboxToRejectRequest(selectedIds));
  };

  const handleEditDraft = (isGlobalSearch?: boolean) => {
    const draftId =
      id ||
      (isGlobalSearch ? selectedSearchItems[0]?.id : selectedDraftItems[0]?.id);
    navigate(`${PATHES.FILE_RECIPIENTS}/${draftId}`);
  };

  const handleDeleteDraft = (isGlobalSearch?: boolean) => {
    const itemsToDelete = isGlobalSearch
      ? selectedSearchItems
      : selectedDraftItems;
    const selectedIds = id ? [id] : itemsToDelete.map((el) => el.id);
    dispatch(setIsDeleteDraftItemsModal(true));
    dispatch(setDraftsToDelete(selectedIds));
  };

  const handleDownloadDraftDocument = (isGlobalSearch?: boolean) => {
    const itemsToDownload = isGlobalSearch
      ? selectedSearchItems
      : selectedDraftItems;
    id
      ? downloadDraftDoc(id)
      : itemsToDownload.forEach((item) => item.id && downloadDraftDoc(item.id));
  };

  const handleDownloadSentDocument = (isGlobalSearch?: boolean) => {
    const itemsToDownload = isGlobalSearch
      ? selectedSearchItems
      : selectedSentItems;
    id
      ? downloadSentDoc(id)
      : itemsToDownload.forEach((item) => item.id && downloadSentDoc(item.id));
  };

  const handleDownloadInboxDocument = (isGlobalSearch?: boolean) => {
    const itemsToDownload = isGlobalSearch
      ? selectedSearchItems
      : selectedInboxItems;
    id
      ? downloadInboxDoc(id)
      : itemsToDownload.forEach((item) => item.id && downloadInboxDoc(item.id));
  };

  const handleMoveSentDocument = (isGlobalSearch?: boolean) => {
    const itemsToMove = isGlobalSearch
      ? selectedSearchItems
      : selectedSentItems;
    const items =
      sentToMove.length > 0 ? [...sentToMove] : itemsToMove.map((el) => el.id);
    dispatch(setItemsToMove(id ? [id] : items));
    dispatch(setSentIsMovingItemsModal(true));
  };

  const handleMoveInboxDocument = (isGlobalSearch?: boolean) => {
    const itemsToMove = isGlobalSearch
      ? selectedSearchItems
      : selectedInboxItems;
    const items =
      inboxToMove.length > 0
        ? [...inboxToMove]
        : itemsToMove.map((el) => el.id);
    dispatch(setItemsToMove(id ? [id] : items));
    dispatch(setSentIsMovingItemsModal(true));
  };

  const handleRenameSentDocument = (isGlobalSearch?: boolean) => {
    const itemsToRename = isGlobalSearch
      ? selectedSearchItems
      : selectedSentItems;
    dispatch(
      setRenameModalItem(
        currentContextFolder?.id || id || itemsToRename.map((el) => el.id)[0],
      ),
    );
  };

  const handleRenameInboxDocument = (isGlobalSearch?: boolean) => {
    const itemsToRename = isGlobalSearch
      ? selectedSearchItems
      : selectedInboxItems;
    dispatch(
      setRenameModalItem(
        currentContextFolder?.id || id || itemsToRename.map((el) => el.id)[0],
      ),
    );
  };

  const handleSignInboxDocument = (isGlobalSearch?: boolean) => {
    const itemsToSign = isGlobalSearch
      ? selectedSearchItems
      : selectedInboxItems;
    dispatch(setSearch(""));
    dispatch(setInboxDoc(null));
    localStorage.removeItem("instantJSON");
    id
      ? navigate(`${PATHES.INBOX_DOCUMENT}/${id}`)
      : navigate(`${PATHES.INBOX_DOCUMENT}/${itemsToSign[0].id}`);
  };

  const handleShowViewHistory = () => {
    dispatch(setIsViewHistoryModal(true));
  };

  const getSigningsAction = ({
    name,
    signingsType,
    signingsStatus,
    isGlobalSearch,
    isViewHistory,
  }: {
    name: IconName;
    signingsType: SigningsType;
    signingsStatus?: TSharedDocumentStatus;
    isGlobalSearch?: boolean;
    isViewHistory?: boolean;
  }) => {
    if (isViewHistory) {
      switch (name) {
        case "info":
          return handleShowViewHistory;
        default:
          return () => {};
      }
    }
    if (signingsType === "draft") {
      switch (name) {
        case "edit":
          return () => handleEditDraft(isGlobalSearch);
        case "trash":
          return () => handleDeleteDraft(isGlobalSearch);
        case "download":
          return () => handleDownloadDraftDocument(isGlobalSearch);

        default:
          return () => {};
      }
    }
    if (signingsType === "sent") {
      switch (name) {
        case "close-in-circle":
          return () => handleCancelRequset(isGlobalSearch);
        case "trash":
          return () => handleDeleteSent(isGlobalSearch);
        case "download":
          return () => handleDownloadSentDocument(isGlobalSearch);
        case "move":
          return () => handleMoveSentDocument(isGlobalSearch);
        case "rename":
          return () => handleRenameSentDocument(isGlobalSearch);

        default:
          return () => {};
      }
    }
    if (signingsType === "inbox") {
      switch (name) {
        case "close-in-circle":
          return () => handleRejectRequest(isGlobalSearch);
        case "trash":
          return () => handleDeleteInbox(isGlobalSearch);
        case "download":
          return () => handleDownloadInboxDocument(isGlobalSearch);
        case "move":
          return () => handleMoveInboxDocument(isGlobalSearch);
        case "rename":
          return () => handleRenameInboxDocument(isGlobalSearch);
        case "sign":
          return () => handleSignInboxDocument(isGlobalSearch);

        default:
          return () => {};
      }
    }
    return () => {};
  };

  const getAction = ({
    name,
    isTrash,
    isContact,
    signingsType,
    signingsStatus,
    isGlobalSearch,
    isViewHistory,
  }: IAction) => {
    if (signingsType) {
      return getSigningsAction({
        name,
        signingsType,
        signingsStatus,
        isGlobalSearch,
        isViewHistory,
      });
    }
    switch (name) {
      case "download":
        return () => handleDownload(isGlobalSearch);
      case "share":
        return () => handleShare(isGlobalSearch);
      case "move":
        return () => handleMove(isGlobalSearch);
      case "rename":
        return () => handleRename(isGlobalSearch);
      case "trash":
        return getDeleteFunction({ name, isTrash, isContact, isGlobalSearch });
      case "add-folder":
        return handleOpenCreateModal;
      case "restore":
        return () => handleRestore(isGlobalSearch);
      case "edit":
        return isContact ? handleEditContact : () => {};

      default:
        return () => {};
    }
  };

  return {
    handleDownload,
    handleShare,
    handleMove,
    handleMoveSuccess,
    handleRename,
    handleCertify,
    handleDelete,
    handleDeleteSuccess,
    handleOpenCreateModal,
    handleHardDelete,
    handleHardDeleteSuccess,
    handleRestore,
    handleRestoreSuccess,
    getAction,
    handleDeleteSent,
    handleRenameInboxDocument,
    handleDeleteInbox,
    handleRejectRequest,
    handleMoveInboxDocument,
    handleShowViewHistory,
    handleDeleteInboxSuccess,
    handleCancelRequset,
    handleDeleteSentSuccess,
    handleMoveSentDocument,
  };
};
