import { FC, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { useParams } from "react-router-dom";

import { getProducts, getStripeProration } from "store/subscription/thunks";
import { useAppDispatch, useAppSelector, subscription } from "store";

import { PaymentForm, PaymentCard } from "components";
// import { STRIPE_PUBLIC_KEY } from "constants/index";

import { palette } from "utils";

type SubscriptionPaymentProps = {
  isUpdate?: boolean;
  isUpdateMethod?: boolean;
};

const stripePromise = await loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY || "",
);

export const SubscriptionPayment: FC<SubscriptionPaymentProps> = ({
  isUpdate,
  isUpdateMethod,
}) => {
  const dispatch = useAppDispatch();
  const { products } = useAppSelector(subscription);
  const { productId } = useParams();
  const selectedProduct = products?.find((el) => el.id === productId);

  useEffect(() => {
    if (products.length === 0) {
      dispatch(getProducts());
    }
  }, [dispatch, products.length]);

  useEffect(() => {
    if (productId) {
      dispatch(getStripeProration(productId));
    }
  }, [dispatch, productId]);

  const paymentAmount =
    selectedProduct?.sku === "month"
      ? Number(selectedProduct?.monthlyPriceAmount) * 100
      : Number(selectedProduct?.monthlyPriceAmount) * 100 * 12;

  const options: StripeElementsOptions = {
    mode: "subscription",
    amount: paymentAmount || 100,
    currency: "usd",
    payment_method_types: ["card"],
    fonts: [
      {
        cssSrc: "https://fonts.googleapis.com/css2?family=Urbanist",
      },
    ],
    appearance: {
      theme: "flat",
      rules: {
        ".Input ": {
          backgroundColor: palette.grey10,
          borderRadius: "10px",
          fontFamily: "Urbanist",
          fontSize: "16px",
        },
        ".Input::placeholder": {
          color: palette.grey70,
        },
        ".Label ": {
          fontFamily: "Urbanist",
          fontSize: "12px",
        },
      },
      variables: {
        colorText: palette.black,
      },
    },
  };

  const updateOptions: StripeElementsOptions = {
    mode: "setup",
    paymentMethodTypes: ["card"],
    captureMethod: "manual",
    paymentMethodCreation: "manual",
    fonts: [
      {
        cssSrc: "https://fonts.googleapis.com/css2?family=Urbanist",
      },
    ],
    appearance: {
      theme: "flat",
      rules: {
        ".Input ": {
          backgroundColor: palette.grey10,
          borderRadius: "10px",
          fontFamily: "Urbanist",
          fontSize: "16px",
        },
        ".Input::placeholder": {
          color: palette.grey70,
        },
        ".Label ": {
          fontFamily: "Urbanist",
          fontSize: "12px",
        },
      },
      variables: {
        colorText: palette.black,
      },
    },
  };

  if (!selectedProduct && !isUpdateMethod) {
    return <></>;
  }

  if (isUpdateMethod) {
    return (
      <Elements stripe={stripePromise} options={updateOptions}>
        <PaymentCard />
      </Elements>
    );
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentForm isUpdate={isUpdate} />
    </Elements>
  );
};
