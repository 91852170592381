import { FC } from "react";
import { useTranslation } from "react-i18next";

import { cs } from "utils";

import signIcon from "assets/img/pdfView/sign.svg";
import eraseIcon from "assets/img/pdfView/erase.svg";

import styles from "./styles.module.scss";

type CounterInfoProps = {
  totalSigned: number;
  requiredSigned: number;
  optionalSigned: number;
  totalOptionalWidgets: number;
  totalRequiredWidgets: number;
  handleSignAll?: () => void;
  handleCleanAll?: () => void;
  isSignAllDisabled: boolean;
};

export const CounterInfo: FC<CounterInfoProps> = ({
  totalSigned,
  requiredSigned,
  optionalSigned,
  totalOptionalWidgets,
  totalRequiredWidgets,
  isSignAllDisabled,
  handleSignAll = () => {},
  handleCleanAll = () => {},
}) => {
  const { t } = useTranslation("RecipientSigningHeader");
  const isAllRequiredSigned =
    totalRequiredWidgets > 0 && totalRequiredWidgets === requiredSigned;
  const isAllOptionalSigned =
    totalOptionalWidgets > 0 && totalOptionalWidgets === optionalSigned;
  return (
    <nav className={styles.CounterInfo}>
      <div
        onClick={() => {
          !isSignAllDisabled && handleSignAll();
        }}
        className={cs([styles.button, isSignAllDisabled && styles.disabled])}
      >
        <img src={signIcon} alt="sign" />
        {t("signAll")}
      </div>
      <div
        onClick={() => {
          totalSigned !== 0 && handleCleanAll();
        }}
        className={cs([styles.button, totalSigned === 0 && styles.disabled])}
      >
        <img src={eraseIcon} alt="clear" />
        {t("clearAll")}
      </div>
      <div className={styles.statusWrap}>
        <div
          className={cs([
            styles.status,
            isAllRequiredSigned && styles.completed,
          ])}
        >
          {t("mandatory")} {requiredSigned}/{totalRequiredWidgets}
        </div>
      </div>
      {totalOptionalWidgets > 0 && (
        <div className={styles.statusOptionalWrap}>
          <div
            className={cs([
              styles.status,
              styles.optional,
              isAllOptionalSigned && styles.completed,
            ])}
          >
            {t("optional")} {optionalSigned}/{totalOptionalWidgets}
          </div>
        </div>
      )}
    </nav>
  );
};
