import { FC, useEffect, useState, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Annotation, Instance } from "pspdfkit";
import OutsideClickHandler from "react-outside-click-handler";
import useWebSocket from "react-use-websocket";

import { useAppDispatch, useAppSelector, signings } from "store";
import { ThemeContext } from "providers";
import {
  setInboxDoc,
  setIsInboxRejectRequestModal,
  setGuestRecipientDoc,
} from "store/signings";
import { SharedDocuments } from "api";

import { RejectSigningModal } from "components";
import { Button, Modal, Spinner } from "components/UI";

import { PATHES } from "constants/pathes";
import { WS_URL } from "constants/index";

import { toastSuccess, cs, palette, getPointerTitle } from "utils";
import { useActions, useInstance } from "hooks";

// import { GoNextButton } from "../GoNextButton";
import styles from "./styles.module.scss";

type RecipientSigningFooterProps = {
  stateChanged: boolean;
  stateInstance: Instance | null;
  statePSPDFKit: any;
  handleSignAll?: () => void;
  handleCleanAll?: () => void;
  totalSigned: number;
  optionalSigned: number;
  requiredSigned: number;
  totalOptionalWidgets: number;
  totalRequiredWidgets: number;
  isSignAllDisabled: boolean;
  isFooter: boolean;
  setIsFooter: (value: boolean) => void;
  isSigningStarted: boolean;
  setIsSigningStarted: (value: boolean) => void;
  onDownloadFile: () => void;
};

export const RecipientSigningFooter: FC<RecipientSigningFooterProps> = ({
  stateChanged,
  stateInstance,
  statePSPDFKit,
  handleSignAll = () => {},
  handleCleanAll = () => {},
  totalSigned,
  optionalSigned,
  requiredSigned,
  totalOptionalWidgets,
  totalRequiredWidgets,
  isSignAllDisabled,
  isFooter,
  setIsFooter,
  isSigningStarted,
  setIsSigningStarted,
  onDownloadFile,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("RecipientSigningFooter");
  const { t: tS } = useTranslation("RequestSigning", {
    keyPrefix: "SignatureWidget",
  });
  const { t: tH } = useTranslation("RecipientSigningHeader");
  // const [isFooter, setIsFooter] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFullyCompleted, setIsFullyCompleted] = useState<boolean>(false);
  const [isMobileMenu, setIsMobileMenu] = useState<boolean>(false);
  // const [totalWidgets, setTotalWidgets] = useState<number>(0);
  // const [totalSigned, setTotalSigned] = useState<number>(0);
  const [fromTop, setFromTop] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isGoNext, setGoNext] = useState<boolean>(false);
  const [widgetType, setWidgetType] = useState<string>("");
  const [isSkipped, setIsSkipped] = useState<boolean>(false);
  const [skippedWidgets, setSkippedWidgets] = useState<string[]>([]);

  const { inboxDoc, guestRecipientDoc, isInboxRejectRequestModal } =
    useAppSelector(signings);
  const navigate = useNavigate();
  const { linkId } = useParams();
  const { isMobile } = useContext(ThemeContext);
  const { handleRejectRequest } = useActions(linkId || "");
  const { getDefaultSignatureImage, getUnsignedWidgets, zoomIndex } =
    useInstance();
  const isOptional =
    widgetType.startsWith("CHECKBOX_WIDGET") ||
    widgetType.startsWith("TEXT_WIDGET");

  const checkIfFilledWithSignatures = useCallback(async () => {
    if (stateInstance && statePSPDFKit) {
      const totalPages = stateInstance?.totalPageCount;

      let widgetsSignaturesToSignCount = 0;
      let widgetsInitialsToSignCount = 0;
      let widgetsDateCount = 0;
      let widgetsCheckboxCount = 0;
      let widgetsTextCount = 0;

      let signaturesCount = 0;
      let initialsCount = 0;

      const pageIndexes = Array.from(Array(totalPages).keys());
      const allFormFieldValues = stateInstance.getFormFieldValues();

      try {
        if (pageIndexes.length > 0 && statePSPDFKit && stateInstance) {
          await Promise.all(
            pageIndexes.map(async (page) => {
              try {
                const allAnnotations =
                  stateInstance.getAnnotations &&
                  (await stateInstance.getAnnotations(page));

                const widgetsSignaturesToSign = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("SIGNATURE_WIDGET"),
                );
                const widgetsInitialsToSign = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("SIGNATURE_INITIALS"),
                );
                const widgetsDate = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("DATE_WIDGET"),
                );
                const widgetsCheckbox = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("CHECKBOX_WIDGET"),
                );
                const widgetsText = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("TEXT_WIDGET"),
                );
                const signatures = allAnnotations.filter((el: Annotation) => {
                  return (
                    el.isSignature &&
                    !el.customData?.isInitial &&
                    !el.creatorName
                  );
                });
                const initials = allAnnotations.filter(
                  (el: Annotation) =>
                    el.isSignature &&
                    el.customData?.isInitial &&
                    !el.creatorName,
                );

                // Increment counts
                widgetsSignaturesToSignCount += widgetsSignaturesToSign.size;
                widgetsInitialsToSignCount += widgetsInitialsToSign.size;
                widgetsDateCount += widgetsDate.size;
                widgetsCheckboxCount += widgetsCheckbox.size;
                widgetsTextCount += widgetsText.size;

                signaturesCount += signatures.size;
                initialsCount += initials.size;
              } catch (error) {
                console.log("error:", error);
              }
            }),
          );
        }
      } catch (error) {
        console.log("error:", error);
      }
      const isAllSignaturesFilled =
        widgetsSignaturesToSignCount > 0
          ? signaturesCount === widgetsSignaturesToSignCount
          : true;
      const isAllInitialsFilled =
        widgetsInitialsToSignCount > 0
          ? initialsCount === widgetsInitialsToSignCount
          : true;
      const isAllDateFilled =
        widgetsDateCount > 0
          ? Object?.entries(allFormFieldValues)
              ?.filter((el: any) => el[0].startsWith("DATE_WIDGET"))
              .filter((el) => el[1] && el[1][0] !== "")?.length ===
            widgetsDateCount
          : true;
      const isAllCheckboxFilled =
        widgetsCheckboxCount > 0
          ? Object?.entries(allFormFieldValues)
              ?.filter((el: any) => el[0].startsWith("CHECKBOX_WIDGET"))
              .filter((el) => el[1] && el[1][0] !== "Off")?.length ===
            widgetsCheckboxCount
          : true;
      const allTextWidgets = Object?.entries(allFormFieldValues)?.filter(
        (el: any) => el[0].startsWith("TEXT_WIDGET"),
      );
      const allCheckboxWidgets = Object?.entries(allFormFieldValues)?.filter(
        (el: any) => el[0].startsWith("CHECKBOX_WIDGET"),
      );
      const isAllTextFilled =
        widgetsTextCount > 0
          ? allTextWidgets?.filter((el: any) => el[1] && el[1][0] !== "")
              ?.length === widgetsTextCount
          : true;
      if (
        (isAllSignaturesFilled && isAllInitialsFilled && isAllDateFilled) ||
        (requiredSigned > 0 && requiredSigned === totalRequiredWidgets)
      ) {
        setIsFooter(true);
        setIsFullyCompleted(
          allTextWidgets.length > 0 || allCheckboxWidgets
            ? (isAllTextFilled && isAllCheckboxFilled) ||
                optionalSigned === totalOptionalWidgets
            : true,
        );
      } else {
        setIsFooter(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stateInstance,
    statePSPDFKit,
    stateChanged,
    optionalSigned,
    requiredSigned,
    totalOptionalWidgets,
    totalRequiredWidgets,
    setIsFooter,
  ]);

  const handleFinish = async () => {
    const savedInstant = localStorage.getItem("instantJSON");
    const parsedInstant = savedInstant && JSON.parse(savedInstant);
    const defSignatureImage = await getDefaultSignatureImage();
    const defInitialImage = await getDefaultSignatureImage(true);
    const defImage = defSignatureImage || defInitialImage;
    if (isFooter && parsedInstant && inboxDoc?.id && defImage) {
      const res = await SharedDocuments.signInboxDoc(inboxDoc.id, {
        instantJson: parsedInstant,
        signatureFile: defImage,
      });
      if (res) {
        setIsLoading(true);
        // await sleep(10000);
        // setIsLoading(false);
        // navigate(PATHES.INBOX);
        // toastSuccess(t("toastSuccess"));
      }
    }
    if (
      isFooter &&
      parsedInstant &&
      guestRecipientDoc?.id &&
      linkId &&
      defImage
    ) {
      const res = await SharedDocuments.signGuestRecipientDoc(linkId, {
        instantJson: parsedInstant,
        signatureFile: defImage,
      });
      if (res) {
        setIsLoading(true);
        // await sleep(10000);
        // setIsLoading(false);
        // navigate(`${PATHES.GUEST_RECIPIENT_DOCUMENT_READY}/${linkId}`);
      }
    }
  };

  const handleOpenMobileMenu = () => {
    setIsMobileMenu(true);
  };

  const handleCloseMobileMenu = () => {
    setIsMobileMenu(false);
  };

  const handleRejectDocument = () => {
    if (guestRecipientDoc?.id) {
      handleRejectRequest();
      handleCloseMobileMenu();
    }
  };

  const handleSubmitReject = () => {
    dispatch(setIsInboxRejectRequestModal(false));
    localStorage.removeItem("instantJSON");
    dispatch(setInboxDoc(null));
    dispatch(setGuestRecipientDoc(null));
    navigate(PATHES.INBOX_VOIDED);
    toastSuccess(tH("toastRejectSuccess"));
  };

  const handleCloseRejectModal = () => {
    dispatch(setIsInboxRejectRequestModal(false));
  };

  const handleMessage = async (message: any) => {
    const { data } = (message.data && JSON.parse(message.data)) || {};
    console.log("data?.type:", data?.type);
    if (data?.type === "signaturesMergeSuccess") {
      if (linkId) {
        setIsLoading(false);
        sessionStorage.removeItem("IsSigningStarted");
        setIsSigningStarted(false);
        navigate(`${PATHES.GUEST_RECIPIENT_DOCUMENT_READY}/${linkId}`);
      }
    }
  };

  const handleStart = () => {
    if (!isSigningStarted) {
      sessionStorage.setItem("IsSigningStarted", "true");
      setIsSigningStarted(true);
    }
  };

  const handleSkip = () => {
    setIsSkipped(true);
    setGoNext(true);
  };

  const handleGoNext = useCallback(async () => {
    if (stateInstance) {
      const allWidgets = await getUnsignedWidgets({
        instance: stateInstance,
        PSPDFKit: statePSPDFKit,
      });
      const allFormFieldValues = stateInstance.getFormFieldValues();
      const unsignedWidgets: any[] = [];
      await Promise.all(
        allWidgets.map(async (el) => {
          const isOverLapped =
            await stateInstance.getOverlappingAnnotations(el);
          const formFieldName = allFormFieldValues[el.formFieldName];
          if (
            isOverLapped.size === 0 && el.formFieldName
              ? (Array.isArray(formFieldName) && formFieldName[0] === "Off") ||
                allFormFieldValues[el.formFieldName] === "" ||
                el.formFieldName?.startsWith("SIGNATURE")
              : true
          ) {
            unsignedWidgets.push(el);
          }
        }),
      );

      const sortedWidgets = [...unsignedWidgets].sort((a, b) => {
        if (a.pageIndex === b.pageIndex) {
          return a.boundingBox.top - b.boundingBox.top;
        }
        return a.pageIndex - b.pageIndex;
      });
      const filteredWidgets = sortedWidgets.filter((el) => {
        return !skippedWidgets.includes(el.id);
      });
      if (isSkipped) {
        setSkippedWidgets((prevState) => [
          ...prevState,
          filteredWidgets[0]?.id,
        ]);
      }

      const nextWidget = filteredWidgets[0];

      if (nextWidget) {
        if (isGoNext || isFooter) {
          setWidgetType(nextWidget.formFieldName);

          stateInstance.jumpAndZoomToRect(
            nextWidget.pageIndex,
            nextWidget.boundingBox,
          );
          stateInstance.setViewState((state) => state.set("zoom", zoomIndex));
          if (nextWidget?.boundingBox) {
            setFromTop(
              nextWidget.boundingBox.top * zoomIndex +
                nextWidget.boundingBox.height / 2 || 0,
            );
            setCurrentPage(nextWidget.pageIndex);
          }

          setGoNext(isSkipped);
          setIsSkipped(false);
        }
      }
    }
  }, [
    getUnsignedWidgets,
    isGoNext,
    stateInstance,
    statePSPDFKit,
    isFooter,
    zoomIndex,
    isSkipped,
    skippedWidgets,
  ]);

  // const initTotalWidgets = useCallback(async () => {
  //   if (stateInstance) {
  //     const totalWidgetsCount = await getTotalWidgetsCount({
  //       instance: stateInstance,
  //       PSPDFKit: statePSPDFKit,
  //     });

  //     setTotalWidgets(totalWidgetsCount);
  //   }
  // }, [getTotalWidgetsCount, stateInstance, statePSPDFKit]);

  // const initTotalSigned = useCallback(async () => {
  //   if (stateInstance) {
  //     const signedWidgets = await getSignedWidgets({
  //       instance: stateInstance,
  //     });

  //     setTotalSigned(signedWidgets || 0);
  //   }
  // }, [getSignedWidgets, stateInstance]);

  const initTotalUnsigned = useCallback(async () => {
    if (isSigningStarted) {
      handleGoNext();
    }
  }, [handleGoNext, isSigningStarted]);

  useEffect(() => {
    initTotalUnsigned();
  }, [initTotalUnsigned]);

  // useEffect(() => {
  //   initTotalWidgets();
  // }, [initTotalWidgets]);

  // useEffect(() => {
  //   initTotalSigned();
  // }, [initTotalSigned, stateChanged]);

  useEffect(() => {
    setGoNext(true);
  }, [totalSigned]);

  useEffect(() => {
    const zoomInstance =
      stateInstance?.contentDocument.querySelectorAll(".PSPDFKit-Spread");
    const oldPointer =
      stateInstance?.contentDocument.querySelector<HTMLElement>(
        "#documentWidgetPointer",
      );
    oldPointer && oldPointer.parentNode?.removeChild(oldPointer);
    const pageInstance = zoomInstance && zoomInstance[currentPage];
    const handleStart = () => {
      if (!isSigningStarted) {
        sessionStorage.setItem("IsSigningStarted", "true");
        setIsSigningStarted(true);
        handleGoNext();
      }
    };
    const handleSkip = () => {
      setIsSkipped(true);
      setGoNext(true);
    };
    if (pageInstance && !isMobile) {
      const buttonEl = document.createElement("div");
      buttonEl.id = "documentWidgetPointer";
      buttonEl.style.position = "absolute";
      buttonEl.style.left = isMobile ? "-60px" : "-130px";
      buttonEl.style.top = `0px`;
      buttonEl.style.width = isMobile ? "60px" : "130px";
      buttonEl.style.height = isMobile ? "20px" : "40px";
      buttonEl.style.backgroundColor = palette.actionGreen;
      buttonEl.style.borderRadius = "5px 0 0 5px";
      buttonEl.style.color = palette.black;
      buttonEl.style.zIndex = "102";
      buttonEl.style.fontSize = isMobile ? "12px" : "18px";
      buttonEl.style.textAlign = "center";
      buttonEl.style.display = "flex";
      buttonEl.style.justifyContent = "center";
      buttonEl.style.alignItems = "center";
      buttonEl.style.fontFamily = "Clash";
      buttonEl.innerHTML = t("start");
      buttonEl.style.pointerEvents = "all";
      buttonEl.addEventListener(
        "pointerdown",
        isOptional ? handleSkip : handleStart,
        {
          capture: true,
        },
      );

      pageInstance.appendChild(buttonEl);
    }
  }, [
    stateInstance,
    currentPage,
    handleGoNext,
    totalSigned,
    dispatch,
    setIsSigningStarted,
    isMobile,
    t,
    widgetType,
    isSigningStarted,
    isOptional,
  ]);

  useEffect(() => {
    checkIfFilledWithSignatures();
  }, [checkIfFilledWithSignatures]);

  useEffect(() => {
    const pointer = stateInstance?.contentDocument.querySelector<HTMLElement>(
      "#documentWidgetPointer",
    );
    if (pointer && !isMobile) {
      pointer.style.top = `${fromTop}px`;

      // if (!isMobile) {
      pointer.innerHTML = isSigningStarted
        ? getPointerTitle(widgetType)
        : t("start");
      // }

      if (isSigningStarted) {
        const triangleEl = document.createElement("div");
        triangleEl.style.position = "absolute";
        triangleEl.style.top = "0";
        triangleEl.style.right = isMobile ? "-10px" : "-20px"; // Adjust as needed to align the triangle
        triangleEl.style.width = "0";
        triangleEl.style.height = "0";
        triangleEl.style.borderTop = `${
          isMobile ? 10 : 20
        }px solid transparent`;
        triangleEl.style.borderBottom = `${
          isMobile ? 10 : 20
        }px solid transparent`;
        triangleEl.style.borderLeft = `${isMobile ? 10 : 20}px solid ${
          palette.actionGreen
        }`;
        pointer.appendChild(triangleEl);
      }
    }
    if (
      pointer &&
      totalSigned > 0 &&
      totalSigned === totalOptionalWidgets + totalRequiredWidgets
    ) {
      pointer.parentNode?.removeChild(pointer);
    }
  }, [
    fromTop,
    stateInstance,
    isSigningStarted,
    t,
    totalSigned,
    totalOptionalWidgets,
    totalRequiredWidgets,
    handleGoNext,
    widgetType,
    isMobile,
  ]);

  useWebSocket(process.env.REACT_APP_WS_URL || WS_URL, {
    queryParams: { Authorization: linkId || "" },
    shouldReconnect: (closeEvent) => true,
    reconnectAttempts: 3,
    reconnectInterval: 1000,
    onOpen: () => console.log("ws connected"),
    onError: (error) => {},
    onMessage: handleMessage,
  });

  const renderMobileMenu = () => {
    if (isMobile) {
      return (
        <OutsideClickHandler onOutsideClick={handleCloseMobileMenu}>
          <div className={styles.mobileMenuWrap}>
            <Button
              title=""
              onClick={handleOpenMobileMenu}
              variant="outlinedBlack"
              size="lg"
              className={styles.menuButton}
              iconStart="three-dots-horizontal"
            />
            {isMobileMenu && (
              <div className={styles.mobileMenu}>
                <Button
                  variant="textBlack"
                  title={tS("signButtonTitle")}
                  onClick={() => {
                    handleSignAll();
                    handleCloseMobileMenu();
                  }}
                  isDisabled={isSignAllDisabled}
                />
                <Button
                  variant="textBlack"
                  title={tS("cleanButtonTitle")}
                  onClick={() => {
                    handleCleanAll();
                    handleCloseMobileMenu();
                  }}
                  isDisabled={totalSigned === 0}
                />
                <Button
                  variant="textBlack"
                  title={tS("download")}
                  onClick={onDownloadFile}
                />
                <Button
                  title={tH("rejectButtonTitle")}
                  size="md"
                  variant="textBlack"
                  onClick={handleRejectDocument}
                  className={styles.redButton}
                />
              </div>
            )}
          </div>
        </OutsideClickHandler>
      );
    }
  };

  return (
    <>
      <Modal isShowed={isInboxRejectRequestModal}>
        <RejectSigningModal
          onClose={handleCloseRejectModal}
          onSubmit={handleSubmitReject}
          isGuest={!!linkId}
        />
      </Modal>
      {(isFooter || isMobile) && (
        <footer
          className={cs([
            styles.RecipientSigningFooter,
            isFullyCompleted && styles.completed,
            isMobile && styles.mobile,
          ])}
        >
          <div className={styles.buttonsWrap}>
            {isMobile && !isFooter && !isSigningStarted && (
              <Button
                title={t("start")}
                onClick={handleStart}
                variant="primary"
                size="lg"
                className={styles.startButton}
              />
            )}
            {isMobile && !isFooter && isSigningStarted && (
              <Button
                title={getPointerTitle(widgetType)}
                onClick={isOptional ? handleSkip : () => {}}
                variant="primary"
                size="lg"
                className={styles.widgetButton}
              />
            )}
            {isFooter && (
              <Button
                title={
                  isLoading ? (
                    <Spinner color={palette.white} />
                  ) : (
                    t("submitButtonTitle")
                  )
                }
                onClick={handleFinish}
                variant="primary"
                size="lg"
                className={cs([
                  styles.singleButton,
                  isFullyCompleted && styles.completed,
                ])}
              />
            )}
            {renderMobileMenu()}
          </div>
        </footer>
      )}
      {/* <div className={cs([styles.GoNextButton, isFooter && styles.relative])}>
        <div className={styles.wrap}>
          {isFooter && (
            <Button
              title={t("goToNext")}
              onClick={handleGoNext}
              variant="primary"
            />
          )}
        </div>
      </div> */}
    </>
  );
};
