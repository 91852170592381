import { AxiosError, isAxiosError } from "axios";

import { toastError, toastInfo, dashboardErrors } from "utils";
import {
  IHttpRejection,
  ICurrencyISO,
  IProduct,
  ISubscriptionStatus,
  ICard,
  ISubscription,
  ICancelSubscriptionReason,
} from "types";

import { $http } from "./instance";

interface IGetCurrentSubscriptionResponse {
  subscription: ISubscription;
}

interface ICreateStripeSubscriptionRequest {
  productId: string;
  currency?: ICurrencyISO;
}

interface ICreateStripeSubscriptionResponse {
  type: string;
  clientSecret: string;
  id: string;
  status: ISubscriptionStatus;
  mobileSubscriptionLink?: string;
}

interface IUpgradeStripeSubscriptionRequest {
  productId: string;
  currency?: ICurrencyISO;
}

interface IUpgradeStripeSubscriptionResponse {
  message: string;
}

interface ICreateStripeCardRequest {
  id: string;
  last4?: string;
  expirationMonth?: number;
  expirationYear?: number;
}

interface ICreateStripeCardResponse {
  message: string;
}

interface IGetProductsResponse {
  products: IProduct[];
}
interface IActivateTrialResponse {
  message: string;
}

interface IUpgradeMobileSubscriptionRequest {
  productId: string;
  currency?: string;
}
interface IUpgradeMobileSubscriptionResponse {
  type: string;
  clientSecret: string;
  id: string;
  status: ISubscriptionStatus;
  mobileSubscriptionLink: string;
}
interface IGetStripeProrationRequest {
  productId: string;
  currency?: string;
}
interface IGetStripeProrationResponse {
  prorationAmount: number;
}
interface IGetPaymentMethodResponse {
  id: string;
  type: "card";
  card: ICard;
}

interface ICancelStripeSubscriptionRequest {
  cancelReasonIds?: string[];
  textReason?: string;
}
interface ICancelStripeSubscriptionResponse {
  message: string;
}
interface IRenewStripeSubscriptionResponse {
  message: string;
}
interface IGetCancelSubscriptionReasonsResponse {
  reasons: ICancelSubscriptionReason[];
}

interface IRetryStripeSubscriptionResponse {
  message: string;
}

export const Subscriptions = {
  getCurrentSubscription: async (): Promise<
    IGetCurrentSubscriptionResponse | undefined
  > => {
    try {
      const res = await $http.get(`/clients/subscriptions/current`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  createStripeSubscription: async (
    data: ICreateStripeSubscriptionRequest,
  ): Promise<ICreateStripeSubscriptionResponse | undefined> => {
    try {
      const res = await $http.post(`/clients/subscriptions/stripe`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  upgradeStripeSubscription: async (
    data: IUpgradeStripeSubscriptionRequest,
  ): Promise<IUpgradeStripeSubscriptionResponse | undefined> => {
    try {
      const res = await $http.post(
        `/clients/subscriptions/stripe/upgrade`,
        data,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  upgradeMobileSubscription: async (
    data: IUpgradeMobileSubscriptionRequest,
  ): Promise<IUpgradeMobileSubscriptionResponse | undefined> => {
    try {
      const res = await $http.post(
        `/clients/subscriptions/mobile/upgrade`,
        data,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  createStripeCard: async (
    data: ICreateStripeCardRequest,
  ): Promise<ICreateStripeCardResponse | undefined> => {
    try {
      const res = await $http.post(`/clients/subscriptions/stripe/card`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  getProducts: async (): Promise<IGetProductsResponse | undefined> => {
    try {
      const res = await $http.get(`/clients/products`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  activateTrial: async (): Promise<IActivateTrialResponse | undefined> => {
    try {
      const res = await $http.get(`/clients/subscriptions/trial/activate`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  getStripeProration: async ({
    productId,
  }: IGetStripeProrationRequest): Promise<
    IGetStripeProrationResponse | undefined
  > => {
    try {
      const res = await $http.get(
        `/clients/subscriptions/proration?productId=${productId}`,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  getPaymentMethod: async (): Promise<
    IGetPaymentMethodResponse | undefined
  > => {
    try {
      const res = await $http.get(`/clients/subscriptions/payment-method`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  cancelStripeSubscription: async (
    data: ICancelStripeSubscriptionRequest,
  ): Promise<ICancelStripeSubscriptionResponse | undefined> => {
    try {
      const res = await $http.post(
        `/clients/subscriptions/stripe/cancel`,
        data,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  renewStripeSubscription: async (): Promise<
    IRenewStripeSubscriptionResponse | undefined
  > => {
    try {
      const res = await $http.get(`/clients/subscriptions/stripe/renew`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  getCancelSubscriptionReasons: async (): Promise<
    IGetCancelSubscriptionReasonsResponse | undefined
  > => {
    try {
      const res = await $http.get(`/clients/subscriptions/cancel-reasons`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  retryStripeSubscription: async (): Promise<
    IRetryStripeSubscriptionResponse | undefined
  > => {
    try {
      const res = await $http.get(`/clients/subscriptions/stripe/retry`);
      return res.data;
    } catch (e) {
      const error = e as AxiosError<IHttpRejection>;
      if (error.message === "api.errors.subscription.retryNeedsConfirmation") {
        toastInfo(dashboardErrors(error.message));
        return;
      }
      if (isAxiosError(e)) {
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
};
