import axios from "axios";
import { useNavigate } from "react-router-dom";
import fileDownload from "js-file-download";

import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch, subscription } from "store";
import { setErrorModal } from "store/dashboard";
import {
  setPremiumActivatedModal,
  setPaymentFailedModal,
  setTrialExpireModal,
  setTrialDaysLeft,
  setGiftExpireModal,
  setGiftDaysLeft,
} from "store/subscription";
import {
  getCurrentSubscription,
  getPaymentMethod,
} from "store/subscription/thunks";

import { PATHES } from "constants/pathes";
import { useAuth } from "hooks";
import { toastSuccess } from "utils";

export const useWS = () => {
  const { t: tS } = useTranslation("RequestSigning", {
    keyPrefix: "RequestWidget",
  });
  const { t: tF } = useTranslation("RecipientSigningFooter");
  const dispatch = useAppDispatch();
  const { handleLogout } = useAuth();
  const { mobileSubscriptionLink, currentSubscription } =
    useAppSelector(subscription);

  const navigate = useNavigate();

  const handleMessage = async (message: any) => {
    const { data } = (message.data && JSON.parse(message.data)) || {};
    if (data.platform === "other" && data.type === "userLogin") {
      handleLogout();
    }
    if (data.type === "signingRequestSendingFailed" && data.documentId) {
      dispatch(
        setErrorModal({
          title: tS("signingSendErrorTitle"),
          text: tS("signingSendErrorText"),
          submitButtonTitle: tS("signingSubmitButttonTitle"),
          onSubmit: () => {
            navigate(`${PATHES.SIGNINGS_AREA}/${data.documentId}`);
            dispatch(setErrorModal(null));
          },
          onCancel: () => {
            dispatch(setErrorModal(null));
          },
        }),
      );
    }
    if (data.type === "sharedDocumentDownloadLink") {
      if (data.isSuccess && data.link && data.documentName) {
        axios
          .get(data.link, {
            responseType: "blob",
          })
          .then(async (res) => {
            fileDownload(res.data, `${data.documentName}.pdf`);
          });
      }
    }
    if (data.type === "userBecamePremium" && !mobileSubscriptionLink) {
      dispatch(getCurrentSubscription());
      dispatch(setPremiumActivatedModal(true));
    }
    if (data.type === "paymentFailed") {
      dispatch(getCurrentSubscription());
      dispatch(setPaymentFailedModal(true));
    }
    if (data.type === "trialEndsSoon") {
      dispatch(setTrialExpireModal(true));
      dispatch(setTrialDaysLeft(data.daysLeft));
    }
    if (
      data.type === "userDowngradedToFreemium" &&
      currentSubscription?.onTrial
    ) {
      dispatch(setTrialExpireModal(true));
      dispatch(setTrialDaysLeft(0));
    }
    if (data.type === "manualSubscriptionEndsSoon") {
      dispatch(setGiftExpireModal(true));
      dispatch(setGiftDaysLeft(data.daysLeft));
    }
    if (data.type === "manualSubscriptionExpired") {
      dispatch(setGiftExpireModal(true));
      dispatch(setGiftDaysLeft(0));
    }
    if (data.data?.type === "paymentMethodAttached") {
      dispatch(getPaymentMethod());
    }
    if (data?.type === "signaturesMergeSuccess") {
      sessionStorage.removeItem("IsSigningStarted");
      navigate(PATHES.INBOX);
      toastSuccess(tF("toastSuccess"));
    }
  };

  return {
    handleMessage,
  };
};
