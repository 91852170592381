/* eslint-disable no-underscore-dangle */
import { FC, useState, useRef, useEffect, useCallback } from "react";
import { Annotation, Instance } from "pspdfkit";
// import { useParams } from "react-router-dom";

// import { REACT_APP_PSPDFKIT_ID } from "constants/index"; // update to env secret
import { setStoredInstance } from "store/signatures";
import { setIsDetailedViewMenu } from "store/signings";
import { setActiveRecipient } from "store/requestSigning";
import { useAppDispatch, useAppSelector, requestSigning } from "store";

import { RequestWidget } from "components";

import { getEmptyNode, palette } from "utils";
import { useInstance } from "hooks";

import signIcon from "assets/img/pdfView/sign.svg";
import checkboxIcon from "assets/img/pdfView/checkbox.svg";
import dateIcon from "assets/img/pdfView/date.svg";
import initialIcon from "assets/img/pdfView/initial.svg";
import textIcon from "assets/img/pdfView/add-text.svg";
// import eraseIcon from "assets/img/pdfView/erase.svg";

export const PdfViewerRequestSigning: FC = () => {
  const dispatch = useAppDispatch();
  const { editedDraft, activeRecipient } = useAppSelector(requestSigning);
  // const { draftId } = useParams();
  const {
    saveInkAnnotationAtachment,
    saveImageAnnotationAtachment,
    saveInstanceJSON,
    saveSignatures,
    getDynamicColor,
    getSavedJSON,
  } = useInstance();
  const recipientId = activeRecipient?.email || "";
  const [onPageIndex, setOnPageIndex] = useState<number>(0);
  const [isStateUpdated, setStateUpdated] = useState<boolean>(false);
  // const [isCreateInitial, setCreateInitial] = useState<boolean>(false);
  const [statePSPDFKit, setStatePSPDFKit] = useState<any>(null);
  const [stateInstance, setStateInstance] = useState<Instance | null>(null);
  const containerRef = useRef(null);
  const onPageIndexRef = useRef(onPageIndex);
  onPageIndexRef.current = onPageIndex;
  const isCreateInitial = useRef(false);
  const tempRecipientIdRef = useRef("");

  const isLocal = window.location.href.startsWith("http://localhost:");

  const handleAddInitial = useCallback(async () => {
    if (statePSPDFKit && stateInstance) {
      stateInstance.setViewState((viewState: any) =>
        viewState.set("interactionMode", statePSPDFKit.InteractionMode.PAN),
      );
      sessionStorage.removeItem("selectedMode");
      const instantId = statePSPDFKit.generateInstantId();
      const rectWidth = 110;
      const rectHeight = 75;
      const pageIndex = onPageIndexRef.current;
      const clientRect = new statePSPDFKit.Geometry.Rect({
        left: 800 - rectWidth / 2,
        top: 500 - rectHeight / 2,
        height: rectHeight,
        width: rectWidth,
      });
      const pageRect = stateInstance.transformContentClientToPageSpace(
        clientRect,
        pageIndex,
      ) as any;
      const widget = new statePSPDFKit.Annotations.WidgetAnnotation({
        boundingBox: pageRect,
        backgroundColor: getDynamicColor({ PSPDFKit: statePSPDFKit }),
        formFieldName: `SIGNATURE_INITIALS_WIDGET_${instantId}`,
        id: instantId,
        pageIndex,
        name: instantId,
        customData: {
          isInitial: true,
        },
      });
      const formField = new statePSPDFKit.FormFields.SignatureFormField({
        annotationIds: statePSPDFKit.Immutable.List([widget.id]),
        name: `SIGNATURE_INITIALS_WIDGET_${instantId}`,
        id: instantId,
        options: new statePSPDFKit.Immutable.List([
          new statePSPDFKit.FormOption({
            label: "Check",
            value: "1",
          }),
        ]),
      });
      await stateInstance.create([widget, formField]);
      // isCreateInitial.current = true;
      stateInstance.setViewState((viewState: any) =>
        viewState.set(
          "interactionMode",
          statePSPDFKit.InteractionMode.FORM_CREATOR,
        ),
      );
    }
  }, [getDynamicColor, stateInstance, statePSPDFKit]);

  useEffect(() => {
    // Compose toolbar
    if (stateInstance && statePSPDFKit) {
      const emptyButton = {
        type: "custom",
        id: "emptyButton",
        node: getEmptyNode(6, `1px solid ${palette.grey30}`),
      };
      const initialsButton = {
        type: "custom",
        id: "initials-id",
        title: "Initials",
        icon: initialIcon,
        onPress: handleAddInitial,
      };
      const checkboxButton = {
        type: "custom",
        id: "checkbox-id",
        title: "Checkbox",
        icon: checkboxIcon,
        onPress: async () => {
          if (stateInstance) {
            stateInstance.setViewState((viewState: any) =>
              viewState.set(
                "interactionMode",
                statePSPDFKit.InteractionMode.CHECKBOX_WIDGET,
              ),
            );
          }
        },
      };
      const dateButton = {
        type: "custom",
        id: "date-id",
        title: "Date",
        icon: dateIcon,
        onPress: async () => {
          if (stateInstance) {
            stateInstance.setViewState((viewState: any) =>
              viewState.set(
                "interactionMode",
                statePSPDFKit.InteractionMode.DATE_WIDGET,
              ),
            );
          }
        },
      };
      const signatureButton = {
        type: "custom",
        id: "signature-id",
        title: "Signature",
        icon: signIcon,
        onPress: async () => {
          isCreateInitial.current = false;
          if (stateInstance) {
            stateInstance.setViewState((viewState: any) =>
              viewState.set(
                "interactionMode",
                statePSPDFKit.InteractionMode.SIGNATURE_WIDGET,
              ),
            );
          }
        },
      };
      const textButton = {
        type: "custom",
        id: "text-id",
        title: "Text",
        icon: textIcon,
        onPress: async () => {
          if (stateInstance) {
            stateInstance.setViewState((viewState: any) =>
              viewState.set(
                "interactionMode",
                statePSPDFKit.InteractionMode.TEXT_WIDGET,
              ),
            );
          }
        },
      };
      const toolbarItems = [
        { type: "sidebar-thumbnails" },
        { type: "pager" },
        { type: "zoom-out" },
        { type: "zoom-in" },
        { type: "zoom-mode" },
        { type: "spacer" },
        { type: "form-creator", className: "form-creator" },
        { type: "signature" },
        { type: "text" },
        { type: "line" },
        { type: "document-editor" },
        { type: "search" },
        { type: "spacer" },
      ];
      toolbarItems.push(signatureButton);
      toolbarItems.push(initialsButton);
      toolbarItems.push(dateButton);
      toolbarItems.push(checkboxButton);
      toolbarItems.push(textButton);
      // toolbarItems.push(eraseButton);
      toolbarItems.push(emptyButton);

      stateInstance?.setToolbarItems(toolbarItems as any);
    }
  }, [handleAddInitial, stateInstance, statePSPDFKit]);

  useEffect(() => {
    const savedJSON = getSavedJSON();
    if (!savedJSON) {
      const savedEditedJSON = editedDraft?.instantJson
        ? {
            ...editedDraft?.instantJson,
            ...(editedDraft?.instantJson.annotations?.length > 0 && {
              annotations: editedDraft?.instantJson?.annotations.map(
                (item: any) => ({
                  ...item,
                }),
              ),
            }),
          }
        : null;

      const dataStringified =
        savedEditedJSON && JSON.stringify(savedEditedJSON);
      localStorage.setItem("instantJSON", dataStringified);
    }
  }, [editedDraft?.instantJson, getSavedJSON]);

  useEffect(() => {
    editedDraft && dispatch(setActiveRecipient(editedDraft?.recipients[0]));
  }, [dispatch, editedDraft]);

  useEffect(() => {
    if (stateInstance && statePSPDFKit) {
      stateInstance.setViewState((viewState) => {
        const prevSelectedWidget = sessionStorage.getItem("selectedMode");
        return viewState.set(
          "interactionMode",
          prevSelectedWidget
            ? statePSPDFKit.InteractionMode[prevSelectedWidget]
            : statePSPDFKit.InteractionMode.SIGNATURE_WIDGET,
        );
      });

      stateInstance.setViewState((viewState) =>
        viewState.set("sidebarMode", statePSPDFKit.SidebarMode.THUMBNAILS),
      );

      stateInstance.addEventListener(
        "document.saveStateChange",
        async (event) => {
          if (event.hasUnsavedChanges) {
            dispatch(setStoredInstance(stateInstance));
          }
        },
      );
    }
  }, [stateInstance, statePSPDFKit, dispatch]);

  useEffect(() => {
    tempRecipientIdRef.current = recipientId;

    if (stateInstance && statePSPDFKit) {
      stateInstance.addEventListener("formFields.didSave", () => {
        saveInstanceJSON({
          instance: stateInstance,
          recipientId: tempRecipientIdRef.current,
        });
        const prevSelectedWidget = sessionStorage.getItem("selectedMode");

        prevSelectedWidget &&
          stateInstance.setViewState((viewState) =>
            viewState.set(
              "interactionMode",
              statePSPDFKit.InteractionMode[prevSelectedWidget],
            ),
          );
      });

      stateInstance.addEventListener("annotations.didSave", () => {
        saveInstanceJSON({
          instance: stateInstance,
          recipientId: tempRecipientIdRef.current,
        });
      });

      stateInstance.addEventListener("formFieldValues.didSave", () => {
        saveInstanceJSON({
          instance: stateInstance,
          recipientId: tempRecipientIdRef.current,
        });
      });
    }
  }, [recipientId, saveInstanceJSON, stateInstance, statePSPDFKit]);

  useEffect(() => {
    if (stateInstance && statePSPDFKit) {
      stateInstance.setOnWidgetAnnotationCreationStart(
        (annotation, formField) => {
          let clientRect = new statePSPDFKit.Geometry.Rect(
            annotation.boundingBox,
          );
          let newAnnotation = annotation;

          if (annotation.formFieldName?.startsWith("SIGNATURE")) {
            clientRect = clientRect.set(
              "width",
              annotation.boundingBox.width / 2,
            );
            clientRect = clientRect.set(
              "height",
              annotation.boundingBox.height / 2,
            );
            newAnnotation = newAnnotation.set("boundingBox", clientRect);
          }
          newAnnotation = newAnnotation.set(
            "backgroundColor",
            getDynamicColor({ PSPDFKit: statePSPDFKit }),
          );
          return {
            annotation: newAnnotation,
          };
        },
      );
    }
  }, [getDynamicColor, stateInstance, statePSPDFKit, activeRecipient]);

  useEffect(() => {
    if (editedDraft?.file?.url) {
      const container = containerRef.current;

      let PSPDFKit: any;
      let instance: Instance;

      (async function createContainer() {
        PSPDFKit = await import("pspdfkit");
        // setPSPDFKit(PSPDFKit);

        const savedJSON = getSavedJSON();

        // View state config
        const initialViewState = new PSPDFKit.ViewState({
          scrollMode: PSPDFKit.ScrollMode.CONTINUOUS,
          layoutMode: PSPDFKit.LayoutMode.SINGLE,
          pageSpacing: 10,
          spreadSpacing: 50,
        });

        // Compose toolbar
        // const emptyButton = {
        //   type: "custom",
        //   id: "emptyButton",
        //   node: getEmptyNode(6, `1px solid ${palette.grey30}`),
        // };
        // const initialsButton = {
        //   type: "custom",
        //   id: "initials-id",
        //   title: "Initials",
        //   icon: initialIcon,
        //   onPress: async () => {
        //     if (PSPDFKit && instance) {
        //       instance.setViewState((viewState: any) =>
        //         viewState.set("interactionMode", PSPDFKit.InteractionMode.PAN),
        //       );
        //       sessionStorage.removeItem("selectedMode");
        //       const instantId = PSPDFKit.generateInstantId();
        //       const rectWidth = 110;
        //       const rectHeight = 75;
        //       const pageIndex = onPageIndexRef.current;
        //       const clientRect = new PSPDFKit.Geometry.Rect({
        //         left: 800 - rectWidth / 2,
        //         top: 500 - rectHeight / 2,
        //         height: rectHeight,
        //         width: rectWidth,
        //       });
        //       const pageRect = instance.transformContentClientToPageSpace(
        //         clientRect,
        //         pageIndex,
        //       ) as any;
        //       const widget = new PSPDFKit.Annotations.WidgetAnnotation({
        //         boundingBox: pageRect,
        //         backgroundColor: getDynamicColor({ PSPDFKit }),
        //         formFieldName: `SIGNATURE_INITIALS_WIDGET_${instantId}`,
        //         id: instantId,
        //         pageIndex,
        //         name: instantId,
        //         customData: {
        //           isInitial: true,
        //         },
        //       });
        //       const formField = new PSPDFKit.FormFields.SignatureFormField({
        //         annotationIds: PSPDFKit.Immutable.List([widget.id]),
        //         name: `SIGNATURE_INITIALS_WIDGET_${instantId}`,
        //         id: instantId,
        //         options: new PSPDFKit.Immutable.List([
        //           new PSPDFKit.FormOption({
        //             label: "Check",
        //             value: "1",
        //           }),
        //         ]),
        //       });
        //       await instance.create([widget, formField]);
        //       // isCreateInitial.current = true;
        //       instance.setViewState((viewState: any) =>
        //         viewState.set(
        //           "interactionMode",
        //           PSPDFKit.InteractionMode.FORM_CREATOR,
        //         ),
        //       );
        //     }
        //   },
        // };
        // const checkboxButton = {
        //   type: "custom",
        //   id: "checkbox-id",
        //   title: "Checkbox",
        //   icon: checkboxIcon,
        //   onPress: async () => {
        //     if (instance) {
        //       instance.setViewState((viewState: any) =>
        //         viewState.set(
        //           "interactionMode",
        //           PSPDFKit.InteractionMode.CHECKBOX_WIDGET,
        //         ),
        //       );
        //     }
        //   },
        // };
        // const dateButton = {
        //   type: "custom",
        //   id: "date-id",
        //   title: "Date",
        //   icon: dateIcon,
        //   onPress: async () => {
        //     if (instance) {
        //       instance.setViewState((viewState: any) =>
        //         viewState.set(
        //           "interactionMode",
        //           PSPDFKit.InteractionMode.DATE_WIDGET,
        //         ),
        //       );
        //     }
        //   },
        // };
        // const signatureButton = {
        //   type: "custom",
        //   id: "signature-id",
        //   title: "Signature",
        //   icon: signIcon,
        //   onPress: async () => {
        //     isCreateInitial.current = false;
        //     if (instance) {
        //       instance.setViewState((viewState: any) =>
        //         viewState.set(
        //           "interactionMode",
        //           PSPDFKit.InteractionMode.SIGNATURE_WIDGET,
        //         ),
        //       );
        //     }
        //   },
        // };
        // const textButton = {
        //   type: "custom",
        //   id: "text-id",
        //   title: "Text",
        //   icon: textIcon,
        //   onPress: async () => {
        //     if (instance) {
        //       instance.setViewState((viewState: any) =>
        //         viewState.set(
        //           "interactionMode",
        //           PSPDFKit.InteractionMode.TEXT_WIDGET,
        //         ),
        //       );
        //     }
        //   },
        // };
        // const eraseButton = {
        //   type: "custom",
        //   id: "erase-id",
        //   title: "Erase",
        //   icon: eraseIcon,
        //   onPress: async () => {
        //     if (instance) {
        //       instance.setViewState((viewState: any) =>
        //         viewState.set("interactionMode", null),
        //       );
        //     }
        //   },
        // };
        const toolbarItems = [
          { type: "sidebar-thumbnails" },
          { type: "pager" },
          { type: "zoom-out" },
          { type: "zoom-in" },
          { type: "zoom-mode" },
          { type: "spacer" },
          { type: "form-creator", className: "form-creator" },
          { type: "signature" },
          { type: "text" },
          { type: "line" },
          { type: "document-editor" },
          { type: "search" },
          { type: "spacer" },
        ];
        // toolbarItems.push(signatureButton);
        // toolbarItems.push(initialsButton);
        // toolbarItems.push(dateButton);
        // toolbarItems.push(checkboxButton);
        // toolbarItems.push(textButton);
        // // toolbarItems.push(eraseButton);
        // toolbarItems.push(emptyButton);

        PSPDFKit.unload(container); // Ensure that there's only one PSPDFKit instance.
        setStateInstance(null);
        setStatePSPDFKit(null);
        setStateUpdated(false);

        const {
          UI: { createBlock, Recipes, Interfaces },
        } = PSPDFKit;

        instance = await PSPDFKit.load({
          container,
          document: editedDraft.file.url,
          baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
          initialViewState,
          toolbarItems,
          enableClipboardActions: true,
          enableHistory: true,
          locale: "en",
          styleSheets: ["/custom-pspdfkit.css"],
          licenseKey: isLocal ? "" : process.env.REACT_APP_PSPDFKIT_ID,
          ...(savedJSON && {
            instantJSON: savedJSON,
          }),
          customRenderers: {
            Annotation: ({ annotation }: any) => {
              if (annotation.formFieldName?.startsWith("SIGNATURE")) {
                const placeholderEl = document.createElement("div");
                placeholderEl.style.fontSize = "24px";
                placeholderEl.style.height = "100%";
                placeholderEl.style.display = "flex";
                placeholderEl.style.justifyContent = "center";
                placeholderEl.style.alignItems = "center";
                placeholderEl.innerHTML = annotation.customData?.isInitial
                  ? "Initials"
                  : "Sign";
                return {
                  node: document
                    .createElement("div")
                    .appendChild(placeholderEl),
                  append: true,
                };
              }
            },
          },
          ui: {
            [Interfaces.CreateSignature]: ({ props }: any) => {
              return {
                content: createBlock(
                  Recipes?.CreateSignature,
                  props,
                  ({ ui }: any) => {
                    if (isCreateInitial.current) {
                      ui.getBlockById("title").children = "Create Initial";
                      ui.getBlockById("save-signature-checkbox")._props.label =
                        "Save Initial";

                      const textInput = ui.getBlockById("signature-text-input");
                      textInput._props.placeholder = "Initial";
                      textInput._props.label = "Intial here";
                      textInput._props.clearLabel = "Clear initial";

                      const freehand = ui.getBlockById("freehand-canvas");
                      freehand._props.placeholder = "Intial here";
                      freehand._props.clearLabel = "Clear initial";

                      const fontselect = ui.getBlockById("font-selector");
                      if (fontselect._props.items[0].label === "Signature") {
                        fontselect._props.items = fontselect._props.items.map(
                          (item: any) => {
                            return { id: item.id, label: "Initial" };
                          },
                        );
                      }
                    }
                    return ui.createComponent();
                  },
                ).createComponent(),
              };
            },
          },
        });

        // instance.setViewState((viewState) => {
        //   const prevSelectedWidget = sessionStorage.getItem("selectedMode");
        //   return viewState.set(
        //     "interactionMode",
        //     prevSelectedWidget
        //       ? PSPDFKit.InteractionMode[prevSelectedWidget]
        //       : PSPDFKit.InteractionMode.SIGNATURE_WIDGET,
        //   );
        // });

        // instance.setViewState((viewState) =>
        //   viewState.set("sidebarMode", PSPDFKit.SidebarMode.THUMBNAILS),
        // );

        // instance.addEventListener("document.saveStateChange", async (event) => {
        //   if (event.hasUnsavedChanges) {
        //     dispatch(setStoredInstance(instance));
        //   }
        // });

        // instance.addEventListener("formFields.didSave", () => {
        //   saveInstanceJSON({ instance, recipientId });
        //   const prevSelectedWidget = sessionStorage.getItem("selectedMode");

        //   prevSelectedWidget &&
        //     instance.setViewState((viewState) =>
        //       viewState.set(
        //         "interactionMode",
        //         PSPDFKit.InteractionMode[prevSelectedWidget],
        //       ),
        //     );
        // });

        // instance.addEventListener("annotations.didSave", () => {
        //   saveInstanceJSON({ instance, recipientId });
        // });

        // instance.addEventListener("formFieldValues.didSave", () => {
        //   saveInstanceJSON({ instance, recipientId });
        // });

        instance.addEventListener(
          "viewState.change",
          (viewState, previousViewState) => {
            [
              "SIGNATURE_WIDGET",
              "TEXT_WIDGET",
              "CHECKBOX_WIDGET",
              "DATE_WIDGET",
            ].forEach((item) => {
              if (
                viewState.toJS().interactionMode === "FORM_CREATOR" &&
                previousViewState.toJS().interactionMode === item
              ) {
                sessionStorage.setItem("selectedMode", item);
              }
            });
          },
        );

        instance.addEventListener("annotations.press", async (event) => {
          instance.setViewState((viewState: any) =>
            viewState.set(
              "interactionMode",
              PSPDFKit.InteractionMode.FORM_CREATOR,
            ),
          );
        });

        instance.addEventListener(
          "storedSignatures.create",
          async (annotation: Annotation) => {
            if (annotation instanceof PSPDFKit.Annotations.InkAnnotation) {
              saveInkAnnotationAtachment({ annotation, instance });
            }
            if (annotation.imageAttachmentId) {
              saveImageAnnotationAtachment({ annotation, instance });
            }
            saveSignatures({ annotation, instance, PSPDFKit });
          },
        );

        // instance.setOnWidgetAnnotationCreationStart((annotation, formField) => {
        //   let clientRect = new PSPDFKit.Geometry.Rect(annotation.boundingBox);
        //   let newAnnotation = annotation;

        //   if (annotation.formFieldName?.startsWith("SIGNATURE")) {
        //     clientRect = clientRect.set(
        //       "width",
        //       annotation.boundingBox.width / 2,
        //     );
        //     clientRect = clientRect.set(
        //       "height",
        //       annotation.boundingBox.height / 2,
        //     );
        //     newAnnotation = newAnnotation.set("boundingBox", clientRect);
        //     console.log("newAnnotation:", newAnnotation);
        //   }
        //   newAnnotation = newAnnotation.set(
        //     "backgroundColor",
        //     getDynamicColor({ PSPDFKit }),
        //   );
        //   return {
        //     annotation: newAnnotation,
        //   };
        // });

        instance.addEventListener(
          "viewState.currentPageIndex.change",
          (page: any) => {
            setOnPageIndex(page);
          },
        );

        setStateInstance(instance);
        setStatePSPDFKit(PSPDFKit);

        return () => PSPDFKit && PSPDFKit.unload(container);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedDraft?.file?.url, isCreateInitial, isStateUpdated]);

  return (
    <>
      <div
        onMouseEnter={() => {
          dispatch(setIsDetailedViewMenu(false));
        }}
        ref={containerRef}
        style={{
          width: "100%",
          height: "100%",
          zIndex: "100",
          position: "fixed",
          left: "0",
          top: "9.9rem",
          backgroundColor: "white",
        }}
      />
      <RequestWidget onChange={() => setStateUpdated(true)} />
    </>
  );
};
